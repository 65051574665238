// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #0A2722;
  --ion-color-primary-rgb: 10, 39, 34;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #061B18;
  --ion-color-primary-tint: #465551;

  /** secondary **/
  --ion-color-secondary: #2C1140;
  --ion-color-secondary-rgb: 44, 17, 64;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #180725;
  --ion-color-secondary-tint: #735589;

  /** tertiary **/
  --ion-color-tertiary: #B89662;
  --ion-color-tertiary-rgb: 184, 150, 98;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #A88550;
  --ion-color-tertiary-tint: #E6D9C3;

  /** success **/
  --ion-color-success: #465551;
  --ion-color-success-rgb: 75, 85, 81;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #445F58;
  --ion-color-success-tint: #698A82;

  /** warning **/
  --ion-color-warning: #735589;
  --ion-color-warning-rgb: 115, 85, 137;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #5D4272;
  --ion-color-warning-tint: #997FAC;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #B89662;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #F2E8D1;
  --ion-color-medium-rgb: 242, 232, 209;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #EEDFBD;
  --ion-color-medium-tint: #F6EDDA;

  /** light **/
  --ion-color-light: #FAF6ED;
  --ion-color-light-rgb: 250, 246, 237;
  --ion-color-light-contrast: #B89662;
  --ion-color-light-contrast-rgb: 184, 150, 98;
  --ion-color-light-shade: #F3E5D0;
  --ion-color-light-tint: #FFFDF8;

  /** theme change additions */
  --ion-color-bg: #FFFFFF;
  --ion-color-text: #222222;

  /** typography **/
  --ion-header-font: 'Eksell', sans-serif;
  --ion-button-font: 'Brandon';

  /** grid changes for desktop view **/
  --ion-grid-width-xl: 992px;
  --ion-grid-width-lg: 992px;

  /** background-colors **/
  .bg-light {
    background-color: var(--ion-color-light);
    --ion-background-color: var(--ion-color-light);
  }

  .bg-light-tint {
    background-color: var(--ion-color-light-tint);
    --ion-background-color: var(--ion-color-light-tint);
  }

  .bg-primary {
    background-color: var(--ion-color-primary);
    --ion-background-color: var(--ion-color-primary);
  }

  .bg-primary-tint {
    background-color: var(--ion-color-primary-tint);
    --ion-background-color: var(--ion-color-primary-tint);
  }

  .bg-secondary {
    background-color: var(--ion-color-secondary);
    --ion-background-color: var(--ion-color-secondary);
  }

  .bg-tertiary-tint {
    background-color: var(--ion-color-tertiary-tint);
    --ion-background-color: var(--ion-color-tertiary-tint);
  }

  .bg-light-gradient {
    background: linear-gradient(var(--ion-color-light-shade), var(--ion-color-light));
    --background: linear-gradient(var(--ion-color-light-shade), var(--ion-color-light));
  }

  .overlay-shadow {
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.4) 100%);
    }
  }

  /** header **/
  ion-toolbar {
    --background: var(--ion-color-light);
    --color: var(--ion-color-primary);
    --border-color: var(--ion-color-light);
    font-family: var(--ion-header-font);
  }

  .header-md:after {
    background-image: none;
  }

  --ion-header-height: 50px;

  /** buttons **/
  ion-button {
    --box-shadow: none;
    font-family: var(--ion-button-font);
    text-transform: uppercase;
  }

  /** items **/
  --ion-item-border-color: var(--ion-color-light);

  /** utility **/
  .ion-fixed {
    position: fixed;
    margin-top: 55px;
    z-index: 9;
  }

  .ion-fixed + ion-content {
    top: 50px;
    & >div {
      padding-bottom: 70px;
    }
  }

  app-tab-reading {
    background: var(--ion-color-light-shade);
    ion-content {
      --background: transparent;
    }
  }
}
