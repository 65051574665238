// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@ionic/angular/css/display.css';

@import '~swiper/scss';
@import "~swiper/scss/pagination";
@import "~swiper/scss/effect-coverflow";
// @import url("https://use.typekit.net/bvc2dct.css");

/** fonts **/
@font-face {
  font-family: 'Eksell';
  src: url('assets/fonts/EksellDisplaySmall.eot');
  src: url('assets/fonts/EksellDisplaySmall.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/EksellDisplaySmall.ttf') format('truetype'),
      url('assets/fonts/EksellDisplaySmall.svg#EksellDisplaySmall') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Brandon';
  src: url('assets/fonts/BrandonGrotesque-Bold.eot');
  src: url('assets/fonts/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
      url('assets/fonts/BrandonGrotesque-Bold.ttf') format('truetype'),
      url('assets/fonts/BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EBGaramond';
  src:url('assets/fonts/EBGaramond-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'EBGaramond';
  src:url('assets/fonts/EBGaramond-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'EksellLarge';
  src:url('assets/fonts/EksellDisplay-Large.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

ion-content {
  contain: size style;
  scrollbar-width: none;
  scrollbar-color: var(--ion-color-light-shade) var(--ion-color-light);
}

ion-split-pane {
  --side-width: 300px;
  --side-max-width: 300px;
}

ion-modal {
  --min-width: 300px;
  --max-width: 100%;
  --min-height: 94%;
}

ion-toolbar {
  --border-width: 0 !important;
}

.ion-hide {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

h1, h2, h3, h4, h5, ion-card-title {
  font-family: var(--ion-header-font);
  color: var(--ion-color-primary);
}

ion-skeleton-text {
  --background: var(--ion-color-tertiary);
  --background-rgb: var(--ion-color-tertiary-rgb);
}

.capitals {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--ion-button-font);
  font-size: 12px;
}

.serif {
  font-family: 'EBGaramond';
}

.block {
  display: block !important;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.strikethrough {
  text-decoration: line-through;
}

.height-100 {
  height: 100%;
  padding-bottom: 0;
}

ion-grid.grid-fixed {
  padding: 0;
  margin: 0 auto;
}

ion-button.xsmall {
  text-transform: capitalize;
  font-size: 14px;
  font-family: var(--ion-font-family);
  --border-width: 1px;
  --padding-bottom: 6px;
  --padding-top: 6px;
  --padding-start: 12px;
  --padding-end: 12px;
  letter-spacing: 0;
}

ion-list {
  padding-top: 0 !important;
  .right-button {
    position: absolute;
    right: 0;
    top: 5px;
  }
  ion-item-divider {
    --background: var(--ion-color-bg);
  }
  ion-label {
    h6 {
      line-height: 1.35rem;
    }
  }
}

ion-text, ion-list, ion-item, div, p, ion-card-subtitle, ion-card-content, ion-list-header {
  color: var(--ion-color-text);
  --color: var(--ion-color-text);
}

ion-action-sheet, .action-sheet-button {
  --button-color: var(--ion-color-text);
  --color: var(--ion-color-text);
  color: var(--ion-color-text);
  .action-sheet-title {
    font-weight: bold;
  }
  .action-sheet-sub-title {
    font-weight: normal;
    opacity: 0.8;
  }
  button.danger, button.danger span {
    --color: var(--ion-color-danger);
    color: var(--ion-color-danger);
  }
  &.deckSelect {
    .action-sheet-selected {
      color: var(--ion-color-text);
      span:after {
        content: '👈';
        font-size: 28px;
        position: absolute;
        right: 0;
      }
    }
  }
}

ion-refresher-content {
  background: var(--ion-color-light);
  .refresher-pulling *, .refresher-refreshing *, ion-icon {
    --color: var(--ion-color-light-contrast) !important;
    color: var(--ion-color-light-contrast) !important;
  }
}

ion-checkbox {
  --border-color: var(--ion-color-primary);
}

.fixed {
  position: fixed !important;
  z-index: 9999;
}

.bottom-right {
  bottom: 10px;
  right: 10px;
}

.swiper {
  .swiper-button-prev, .swiper-button-next {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-top: 4px solid #000;
    border-right: 4px solid #000;
    background-image: none;
    stroke-linecap: round;
    margin-top: 10px;
  }
  .swiper-button-prev {
    transform: rotate(-135deg);
    margin-left: 10px;
    left: 0;
    float: left;
  }
  .swiper-button-next {
    transform: rotate(45deg);
    margin-right: 10px;
    right: 0;
    float: right;
  }
  &.white-buttons {
    .swiper-button-prev, .swiper-button-next {
      border-color: white;
    }
  }
  &.primary-buttons {
    .swiper-button-prev, .swiper-button-next {
      border-color: var(--ion-color-primary);
    }
  }
  &.tertiary-buttons {
    .swiper-button-prev, .swiper-button-next {
      border-color: var(--ion-color-tertiary);
    }
  }
  &.buttons-top {
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      top: 25%;
    }
  }
  .swiper-pagination-progressbar {
    background: var(--ion-color-success);
    height: 2px;
    .swiper-pagination-progressbar-fill {
      background: var(--ion-color-tertiary);
    }
  }
  &.segment-swiper {
    .swiper-slide {
      width: auto;
      margin-bottom: 10px;
      min-height: auto;
      height: 60px;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      display: block;
      text-align: center;
      font-family: var(--ion-button-font);
      div {
        color: var(--ion-color-primary);
        background: var(--ion-color-light-tint);
        padding: 5px 15px;
        border-radius: 16px;
        &.current {
          background: var(--ion-color-primary);
          color: var(--ion-color-light);
        }
      }
      .emoji {
        font-size: 1.75em;
      }
    }
  }
  &.context-meanings {
    .swiper-slide {
      height: auto;
      min-height: 350px;
      ion-card {
        height: 100%;
        margin-top: 0;
      }
    }
  }
}

.top-wrapper { // needed for segment-swiper
  background: var(--ion-color-light);
  width: 100%;
  top: 0;
  z-index: 9;
}

ion-segment {
  background: var(--ion-color-light) !important;
}

ion-segment-button {
  --background: var(--ion-color-light);
  --background-checked: var(--ion-color-light);
  --background-activated: var(--ion-color-light-tint);
  --background-hover: var(--ion-color-light-tint);
  --color: var(--ion-color-primary);
  font-family: var(--ion-button-font);
  font-size: 12px;
}

ion-segment.scrollable ion-segment-button {
  min-width: auto !important;
}

ion-segment.icons ion-segment-button {
  min-width: 0 !important;
}

ion-segment.green {
  background: #0A2722 !important;
  ion-segment-button {
    --background: #0A2722;
    --background-checked: #0A2722;
    --background-activated: #145045;
    --background-hover: #145045;
    --color: white;
    --color-checked: #B89662;
    --color-focused: #B89662;
    --indicator-color: #B89662;
    font-family: var(--ion-button-font);
    font-size: 12px;
  }
}

ion-segment.round {
  ion-segment-button {
    min-width: 0;
    overflow: hidden;
    ion-icon {
      max-height: 18px;
      .icon-inner, svg.s-ion-icon {
        --stroke: transparent !important;
      }
    }
  }
  &.ios {
    background: var(--ion-color-light-shade) !important;
    ion-segment-button {
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 10.5px;
      --background: var(--ion-color-light-shade);
      --background-checked: transparent;
      --indicator-box-shadow: inset 0 0 0 1px var(--ion-color-light-shade);
      --margin-top: 3px;
      --margin-bottom: 3px;
      &::before {
        content: none !important;
      }
    }
  }
}

ion-item.warning {
  ion-label {
    font-size: 13px !important;
  }
  ion-button {
    margin-left: 0px;
  }
}

ion-badge.beta-light,
ion-badge.beta-dark {
  top: 2px;
  padding-top: 2px;
  position: relative;
  font-weight: bold;
}

ion-badge.beta-light {
  border: 1px solid var(--ion-color-light);
  background: rgba(var(--ion-color-light-rgb), 0.2);
  color: var(--ion-color-light);
}

ion-badge.beta-dark {
  border: 1px solid var(--ion-color-secondary);
  background: rgba(var(--ion-color-secondary-rgb), 0.2);
  color: var(--ion-color-secondary);
}

.card,
.card img,
.card ion-img,
ion-img::part(image) {
  border-radius: 5px !important;
}

.card.reversed, .card .reversed, .rune.reversed, .rune .reversed {
  transform: rotate(180deg);
}

.no-click {
  pointer-events: none;
}

.disabled {
  cursor: disabled;
  opacity: 0.35;
  img {
    opacity: 0.35;
  }
}

.correct {
  opacity: 0.8;
  img {
    opacity: 0.8;
  }
}

.blur {
  filter: blur(5px);
  pointer-events: none;
}

.search-wrapper {
  background: var(--ion-color-light);
  --background: var(--ion-color-light);
}

.roundedModal {
  &::part(content) {
    border-radius: 20px 20px 0 0; // Here the border radius
    box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

ion-card {
  box-shadow: none !important;
  border: 1px solid var(--ion-color-light);
  border-radius: 0;
  background: var(--ion-color-bg);
  &.secondary, &.primary, &.light {
    p, span, div {
      margin-top: 4px;
      display: inline-block;
    }
  }
  &.secondary {
    background-color: var(--ion-color-secondary);
    h2, h3, h4 {
      color: var(--ion-color-tertiary);
    }
    p, span, div {
      color: var(--ion-color-secondary-contrast);
    }
  }
  &.primary {
    background-color: var(--ion-color-primary);
    h2, h3, h4 {
      color: var(--ion-color-tertiary);
    }
    p, span, div {
      color: var(--ion-color-primary-contrast);
    }
  }
  &.light {
    background: var(--ion-color-light);
    h2, h3, h4 {
      color: var(--ion-color-primary);
    }
    p, span, div {
      color: var(--ion-color-primary-tint);
    }
  }
  &.light-shade {
    background: var(--ion-color-light-shade);
    h2, h3, h4 {
      color: var(--ion-color-primary);
    }
    p, span, div {
      color: var(--ion-color-primary-tint);
    }
  }
  &.round {
    border-radius: 15px !important;
    border: none !important;
  }
  &.round.error {
    border: 1px solid var(--ion-color-danger) !important;
    .capitals, h1, h2, h3, h4, h5, p {
      color: var(--ion-color-danger);
      line-height: 1.5;
    }
  }
  &.img-bg {
    position: relative;
    .bg {
      position: absolute;
      right: -15%;
      top: 0%;
      font-size: 150px;
      height: 150px;
      opacity: 0.25;
    }
  }
}

ion-card.mirrorCard {
  border-radius: 10px;
  border: 1px solid var(--ion-color-light-shade);
  margin: 0 15px;
  .border-top {
    border-top: 1px solid var(--ion-color-light-shade);
    padding: 15px 10px;
    font-size: 20px;
  }
  ion-card-content div {
    min-height: 300px;
  }
  ion-card-title {
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  ion-select {
    --padding-end: 5px;
    color: var(--ion-color-text);
    border-radius: 6px;
    border: 1px solid var(--ion-color-light-shade);
  }
  ion-grid .header {
    font-size: 11px;
    opacity: 0.8;
  }
  .list ion-item {
    --min-height: 28px;
    --padding-start: 10px;
    ion-icon {
      margin-right: 15px;
      color: var(--ion-color-tertiary);
    }
    ion-label {
      margin: 5px 0;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.gradient-blob-dark {
  background: radial-gradient(circle at top left, var(--ion-color-secondary-tint) 0%, var(--ion-color-primary-tint) 80%);
  --background: radial-gradient(circle at top left, var(--ion-color-secondary-tint) 0%, var(--ion-color-primary-tint) 80%);
}
.gradient-blob-light {
  background: radial-gradient(circle at top left, var(--ion-color-success-tint) 0%, var(--ion-color-tertiary-tint) 80%);
  --background: radial-gradient(circle at top left, var(--ion-color-success-tint) 0%, var(--ion-color-tertiary-tint) 80%);
}

.meditation {
  border-radius: 15px;
  padding: 10px 13px 15px 13px;
  border: 1px solid var(--ion-color-light-shade);
  background: var(--ion-color-light);
  position: relative;
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  span {
    color: var(--ion-color-tertiary);
    line-height: 20px;
  }
}

app-product {
  width: 100%;
}

ion-loading {
  --background: rgba(var(--ion-color-light-rgb), 0.75) !important;
  --spinner-color: var(--ion-color-tertiary) !important;
  --min-height: 100vh !important;
  --min-width: 100vw !important;
  .loading-wrapper {
    box-shadow: none !important;
  }
  ion-backdrop {
    opacity: 0 !important;
  }
}

.swiper-pagination-bullet, .swiper-pagination-bullet-active {
  opacity: 1;
}

.auth .swiper-pagination {
  bottom: 30px !important;
}

.swiper-secondary-pagination {
  .swiper-pagination-bullet {
    background: var(--ion-color-secondary-tint);
  }
  .swiper-pagination-bullet-active {
    background: white;
  }
}

.swiper-light-pagination {
  .swiper-pagination-bullet {
    background: var(--ion-color-light-shade);
  }
  .swiper-pagination-bullet-active {
    background: var(--ion-color-primary);
  }
}

.form, .authForm {
  ion-label {
    font-size: 12px !important;
  }
  ion-button {
    text-align: center;
    &:last-child {
      margin-top: 0;
    }
  }
}

.authForm {
  p {
    color: var(--ion-color-text);
  }
  ion-item {
    --border-color: var(--ion-color-tertiary);
    --background-color: var(--ion-color-bg);
    margin-top: 10px;
    ion-label:not(.checkbox-label) {
      color: var(--ion-color-text);
    }
    ion-input {
      --color: var(--ion-color-text);
    }
  }
}

ion-footer ion-button.button-full {
  margin: 0;
  height: 44px;
}

ion-footer ion-button.button-outline, ion-footer .button-has-icon-only {
  color: white;
  --border-color: white;
}

ion-item.report {
  --background: transparent;
  --border-color: var(--ion-color-tertiary-tint) !important;
  ion-label {
    text-transform: capitalize;
    p {
      opacity: 0.65;
    }
  }
  ion-avatar {
    background: var(--ion-color-tertiary);
    width: 50px;
    height: 50px;
    margin: 8px;
  }
  p {
    color: var(--ion-text-color);
  }
}

.standard-alert {
  --background: var(--ion-color-bg) !important;
  .alert-title, .alert-sub-title, .alert-message {
    font-family: var(--ion-font-family) !important;
    color: var(--ion-color-text) !important;
  }
}

.pick-card-wrapper {
  app-rune {
    text-align: center;
    div {
      width: 80%;
      margin: 0 auto;
      aspect-ratio: 1;
      transform: scale(1.25);
    }
  }
}

ion-alert.dialog {
  text-align: center;
  ion-backdrop {
    opacity: 0 !important;
  }
  .alert-message {
    color: white;
  }
  .alert-head {
    display: none;
  }
  .alert-wrapper {
    bottom: 5vh;
    position: absolute;
  }
  .ion-focused {
    background: var(--ion-color-success) !important;
  }
  .alert-button {
    color: var(--ion-color-light);
    text-transform: none;
    width: 100%;
    margin: 0;
    span {
      justify-content: center;
      text-align: center;
    }
  }
}

ion-alert.user {
  --background: var(--ion-color-success) !important;
}

ion-alert.dialog .alert-button {
  color: white !important;
}

ion-title.ios {
  font-weight: normal !important;
}

ion-alert.confirm {
  text-align: center;
  h2 {
    text-align: center;
    color: var(--ion-color-primary) !important;
    &.alert-sub-title {
      font-family: var(--ion-font-family) !important;
    }
  }
  .alert-message {
    color: var(--ion-color-text);
  }
  .alert-button-inner {
    justify-content: center;
  }
  .alert-button-group {
    justify-content: center;
    button {
      border: 1px solid var(--ion-color-tertiary);
      border-radius: 6px;
      color: var(--ion-color-tertiary);
      text-transform: none;
      letter-spacing: 0;
      text-align: center;
      margin: 5px;
    }
    .alert-button-role-confirm {
      color: var(--ion-color-light);
      background: var(--ion-color-tertiary);
      font-weight: bold;
      border: 1px solid var(--ion-color-tertiary) !important;
    }
  }
}

ion-alert.illustration {
  --background: #61477C;
  --height: auto;
  --max-height: 80vh;
  .alert-button-group button {
    border: 1px solid white;
    color: white;
  }
  h2, .alert-message {
    color: #FFFFFF !important;
  }
  .alert-message {
    font-size: 14px;
  }
  img {
    width: 100%;
  }
  .alert-message {
    max-height: calc(100vh - 117px);
  }
  .alert-button-role-confirm {
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
  }
  .alert-tappable.alert-radio {
    height: auto;
    contain: content;
  }
  .alert-radio-label.sc-ion-alert-md,
  .alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
    color: #ffffff !important;
    font-size: 14px;
  }
  .alert-button-group {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .alert-button {
    margin-bottom: 8px;
  }
  .alert-radio-group {
    border-top: none;
    border-bottom: none;
    .alert-radio-icon {
      border-color: var(--ion-color-tertiary) !important;
      .alert-radio-inner {
        background-color: var(--ion-color-tertiary) !important;
      }
    }
  }
}

ion-alert.dark {
  --background: #2C1140;
}

ion-alert.subsetSelect {
  .select-interface-option {
    text-transform: capitalize;
  }
  .alert-title {
    color: var(--ion-color-primary);
  }
  .alert-radio-group, .alert-checkbox-group {
    border-color: var(--ion-color-light);
  }
  .alert-checkbox-label {
    color: var(--ion-color-text);
  }
}

.card-layout {
  .layout-wrapper {
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .layout-wrapper {
    position: relative;
  }
}

.helper {
  opacity: 0.5;
  margin-left: 10px;
  font-style: italic;
  &.error {
    color: var(--ion-color-danger);
  }
}

ion-card.horizontal {
  position: relative;
  ion-thumbnail {
    padding: 0 40px;
    float: left;
    height: 100%;
    position: absolute;
    img, ion-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }
  ion-card-content {
    width: calc(100% - 80px);
    float: left;
    margin-left: 80px;
    color: var(--ion-color-text);
  }
  ion-button {
    margin-left: 95px;
  }
  .actions {
    margin-bottom: -5px;
    text-align: right;
    ion-button {
      margin-bottom: -5px;
      display: inline-block;
      right: -10px;
      position: relative;
    }
  }
}

ion-icon.spread {
  color: var(--ion-color-tertiary);
  opacity: 0.5;
  &.card_1, &.card_2 {
    font-size: 22px;
  }
  &.card_3, &.card_4 {
    font-size: 40px;
  }
  &.card_5, &.card_6, &.card_7, &.card_8, &.card_9, &.card_10, &.card_11, &.card_12, {
    font-size: 60px;
  }
}

.datetime-modal {
  --background: transparent;
  --backdrop-opacity: 0.4 !important;
  padding: 20vh 5vw;
  .modal-wrapper {
    width: auto !important;
    height: auto !important;
  }
  &.header {
    padding: 10vh 10vw;
    div {
      display: block;
      max-width: 350px;
      margin: 0 auto;
    }
    ion-header, ion-item {
      padding: 0;
    }
    ion-datetime {
      margin: 0 auto;
    }
    ion-title {
      padding: 0 15px;
    }
  }
}

ion-modal.loading {
  --border-radius: 8px;
  --width: 90vw;
  --height: 65vh;
  --min-height: 350px;
  --backdrop-opacity: 0.8;
  --background: var(--ion-color-primary-tint);
  overflow: hidden;
  padding-top: 20vh;
  padding-bottom: 20vh;
}

ion-modal.call {
  --border-radius: 15px;
  --width: 90vw;
  --max-width: 480px;
  --height: 100px;
  --min-height: 100px;
  --backdrop-opacity: 0;
  --background: var(--ion-color-secondary-shade);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin-top: 80vh;
}

ion-modal,
ion-modal.call ion-backdrop,
ion-modal.call::part(backdrop),
ion-modal.call .modal-wrapper {
  pointer-events: none !important;
}

// needed for desktop view
.noScale .box-flat, .noScale .cards {
  transform: scale(1) !important;
  border-radius: 10px !important;
}

// modals with a fixed background image
ion-modal.bg-image {
  ion-header, ion-toolbar, ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
  .flex-content {
    margin: 0px auto;
    display: flex; /* Magic begins */
    flex-direction: column;
    justify-content: center; /* Center in main axis */
    align-items: center; /* Center in cross axis */
    position: absolute;
    z-index: -1;
  }
  #photo-fullscreen {
    max-width: 100%;
    max-height: 100%
  }
}

.selectClearButton {
  z-index: 99999;
}

// logged out blurred items or dummy data
.dummy {
  position: relative;
  div {
    position: absolute;
    margin: 10%;
    width: 80%;
    top: 10vh;
    z-index: 999;
    background: var(--ion-color-bg);
    padding: 30px;
    border: 1px solid var(--ion-color-light-shade);
    box-shadow: 0 10px 20px rgba(0,0,0,0.3);
  }
}

//  Force text-wrap on <ion-select>
ion-select::part(placeholder),
ion-select::part(text) {
  white-space: normal !important;
}
// Force text-wrap on <ion-select-option>'s when shown in an Ionic Alert
ion-alert.select-alert.text-wrap {
  // These widths are pretty arbitrary, so you're use-case(s) could required different widths:
  --width: 33vw !important;
  --max-width: 66vw !important;

  .select-interface-option .alert-radio-label,
  .select-interface-option .alert-checkbox-label {
    white-space: normal !important;
  }
}

// any styles coming from [innerHTML] will have to be put here.
.about {
  h3, p {
    line-height: 1.3;
  }
  p {
    color: var(--ion-color-text);
  }
  h3 {
    font-family: var(--ion-font-family);
    font-weight: bold;
    color: var(--ion-color-tertiary);
  }
  .long p {
    margin-bottom: 10px;
  }
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
  .card-layout {
    .layout-wrapper {
      width: 95%;
      height: 100%;
    }
  }
  .modal-wrapper {
    --height: 80vh;
  }
}

// calendar styling must be put here
.monthview-datetable {
  border: 1px solid var(--ion-color-light) !important;
}
.monthview-datetable th,
.monthview-datetable td {
  border: 1px solid transparent !important;
}
.event-detail-container {
  border-top: none !important;
}
.monthview-primary-with-event {
  background-color: transparent !important;
  font-size: 15px;
}
.monthview-secondary-with-event {
  background-color: var(--ion-color-light-tint) !important;
}
.text-muted {
  background-color: var(--ion-color-light) !important;
  font-size: 15px;
}
.monthview-selected {
  background-color: var(--ion-color-light-shade) !important;
}
.monthview-current {
  background-color: var(--ion-color-primary) !important;
  div {
    color: var(--ion-color-bg) !important;
  }
}

// custom date range calendar
ion-calendar-modal {
  .days:not(.between, .startSelection, .endSelection) button:not(.today, .on-selected) p {
    color: var(--ion-color-text) !important;
  }
  .month-box {
    border-bottom: 1px solid var(--ion-color-light-tint) !important;
    h4 {
      color: var(--ion-color-primary) !important;
    }
  }
}

ion-action-sheet.divider {
  .action-sheet-destructive {
    border-bottom: 1px solid var(--ion-color-dark-tint);
    height: 1px;
  }
  .action-sheet-title {
    border-bottom: 1px solid var(--ion-color-dark-tint);
  }
}

ion-action-sheet ion-icon {
  flex-shrink: 0;
}

.article {
  .table {
    width: auto;
    overflow-x: scroll;
  }
  .column {
    float: left;
    width: 50%;
    padding: 3px 8px;
    &:last-child {
      text-align: right;
    }
    &:first-child {
      font-weight: bold;
    }
  }
  .row:after {
    content: " ";
    display: table;
    clear: both;
  }
  .row.three .column {
    width: 40%;
  }
  .fire *, .water *, .air *, .earth * {
    color: white !important;
  }
  .fire {
    background: #AC4128;
  }
  .water {
    background: #1D5467;
  }
  .air {
    background: #B89662;
  }
  .earth {
    background: #195844;
  }
}

app-filter-button {
  background: var(--ion-color-light);
}

// revenuecat
.rcb-modal-dark {
  background-color: white !important;
}

// desktop view
@media (min-width: 992px) {
   ion-menu.web ion-list {
     height: 100vh;
   }
   ion-toolbar,
   ion-item,
   ion-searchbar,
   ion-segment,
   .grid-wrapper,
   .search-wrapper {
     margin: 0;
     padding-right: calc((100% - var(--ion-grid-width-lg)) / 2);
     padding-left: calc((100% - var(--ion-grid-width-lg)) / 2);
   }
   .card-layout .layout-wrapper {
     height: calc(100% - 50px);
   }
   #bg {
     height: 100%;
     max-height: 100vh;
     margin: 0 auto;
     display: block;
   }
   .swiper-button-prev, .swiper-button-next {
     position: relative;
     top: 80px;
   }
}

// custom animations
@keyframes float {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(20px);
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade {
  animation-duration: 10s;
  animation-name: fade;
  animation-direction: alternate;
}

.float {
  animation-duration: 3s;
  animation-name: float;
  animation-direction: alternate;
}
